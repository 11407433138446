<template>
  <van-nav-bar :title="title" :fixed="fixed" @click-left="goBack">
    <cus-icon name="houtui" color="#969799" slot="left" v-if="houtui"/>
    <slot slot="right"></slot>
  </van-nav-bar>
</template>

<script>
import {Icon} from '_c'
import {NavBar} from 'vant';

export default{
  name: 'cus-nav-bar',
  props: {
    houtui: {
      type: Boolean,
      default: true
    },
    title: {
      require: true,
      type: String
    },
    fixed: {
      type: Boolean,
      default: false
    }
  },
  components: {
    [Icon.name]: Icon,
    [NavBar.name]: NavBar
  },
  data(){
    return {
    }
  },
  methods: {
    goBack(){
      this.$router.go(-1);
    }
  }
}
</script>

<style lang='scss'>
.van-nav-bar{
}
.van-nav-bar__title{
  font-size: 17px;
  text-transform: capitalize;
  font-family: PingFang SC, 'Helvetica Neue', Arial, sans-serif;
}
</style>