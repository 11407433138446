<template>
    <div>
        <van-field readonly :label="label" :value="valText" :placeholder="placeholder" :class="{'border':border}" right-icon="arrow" clickable @click="isShowPopup=true"/>
        <van-popup v-model="isShowPopup" position="bottom">
          <van-picker show-toolbar :default-index="defaultIndex" :columns="datas" :loading="isLoading" @cancel="isShowPopup=false" @confirm="onConfirm"/>
        </van-popup>
    </div>
</template>

<script>
import {Field, Picker, Popup} from 'vant';

export default{
    name: 'cus-traffic-field',
    props: {
        url: String,
        //要求columns入参格式必须为：[{text:'文本',value:'值'},......]
        columns: {
            type: [Object, Array],
            //注意此处，返回的是对象或数组，需要通过函数return，不可以直接写default:[]
            default: () => {
                return []
            }
        },
        label: String,
        value: {
            type: [String, Number],
            require: true
        },
        placeholder: {
            type: String,
            default: '请选择'
        },
        border: {
            type: Boolean,
            default: true
        }
    },
    components: {
        [Field.name]: Field,
        [Picker.name]: Picker,
        [Popup.name]: Popup
    },
    data(){
        return {
            isLoading: false,
            isShowPopup: false,
            datas: this.columns,
            defaultIndex: -1,
            valStr: this.value,
            valText: ''
        }
    },
    created(){
        if(this.datas&&this.datas.length!=0){
            this.findIndex();
        }else if(this.url){
            this.loadDatas();
        }
    },
    watch: {
        value: function(n,o){
            this.valStr = n
            this.findIndex()
        }
    },
    methods: {
        loadDatas(){
            this.isLoading = true;
            this.$post(this.url)
            .then(result => {
                this.isLoading = false;
                if(result instanceof Array){
                    this.datas = result;
                }else if(result.object && result.object instanceof Array){
                    this.datas = result.object;
                }
                this.findIndex();
            })
            .catch(error => {
                this.isLoading = false;
                this.$dialog.alert({
                    title: '提示',
                    message: `[${this.label}]选项数据加载失败，请重试`
                });
            });
        },
        findIndex(){
            this.defaultIndex = this.datas.findIndex(item => {
                if(item.value==this.valStr){
                    this.valText = item.text
                    return true
                }
                return false
            });
        },
        onConfirm(item){
            this.isShowPopup = false;
            this.valStr = item.value
            this.valText = item.text
            this.$emit('input',this.valStr)
        }
    }
}
</script>

<style lang='scss'>
.van-cell.border::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 0;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #ebedf0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5);
}
</style>
