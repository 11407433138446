<template>
    <van-loading vertical style="margin-top: calc(100vh/3)">加载中...</van-loading>
</template>

<script>

import {Loading} from 'vant'

export default {
    name: 'cus-loading',
    components: {
        [Loading.name]: Loading
    },
    data(){
        return {
        }
    }
}
</script>

<style lang="less" scoped>

</style>