<template>
    <van-radio-group v-model="sex">
        <van-cell :title="label" :class="{'border':border}">
            <van-radio v-for="item in consts" :name="item.value" :key="item.value">{{item.text}}</van-radio>
        </van-cell>
    </van-radio-group>
</template>

<script>
import {PUBLIC} from '@/libs/const'
import {RadioGroup, Radio, Cell} from 'vant';

export default{
    name: 'cus-idtype-field',
    props: {
        label: {
            type: String,
            default: '证件类型'
        },
        value: Number,
        border: {
            type: Boolean,
            default: true
        }
    },
    components: {
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio,
        [Cell.name]: Cell
    },
    watch: {
        value: function(n,o){
            this.sex = n
        },
        //此处不能用箭头语法，否则找不到this对象
        //参考：https://blog.csdn.net/qq_41009742/article/details/83903096
        sex: function(n,o){
            this.$emit('input',n)
        }
    },
    data(){
        return {
            consts: PUBLIC['idtype'],
            sex: this.value
        }
    }
}
</script>

<style lang='scss' scoped>
.van-cell__title{
  -webkit-box-flex: 0;
  -webkit-flex: none;
  flex: none;
  width: 90px;
}
.van-radio{
  -webkit-box-flex: 0;
  -webkit-flex: none;
  flex: none;
  float: left;
  margin-right: 10px;
}
.van-cell.border::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 0;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #ebedf0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5);
}
</style>
