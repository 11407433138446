<template>
    <van-icon v-if="cus" class-prefix="eou" :name="name" :dot="dot" :size="size" :info="info" :color="color"/>
    <van-icon v-else :name="name" :dot="dot" :size="size" :info="info" :color="color"/>
</template>

<script>
import {Icon} from 'vant';

export default{
    name: 'cus-icon',
    props: {
        cus: {
            type: Boolean,
            default: true
        },
        dot: Boolean,
        name: String,
        size: [Number, String],
        info: [Number, String],
        color: String
    },
    components: {
        [Icon.name]: Icon
    },
    data(){
        return {
        }
    }
}
</script>

<style lang='scss'>
</style>