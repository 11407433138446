<template>
    <div>
        <van-field :class="{'border':border}" readonly :label="label" :value="valueStr" :placeholder="placeholder" right-icon="arrow" clickable @click="isShowPopup=true"/>
        <van-popup v-model="isShowPopup" position="bottom">
            <van-area :area-list="areaList" :value="valueCode" @confirm="confirm" @cancel="cancel"/>
        </van-popup>
    </div>
</template>

<script>
import {Field, Popup,Area } from 'vant';
import areaList from '@/assets/js/area.js';

export default{
    name: 'cus-area-field',
    props: {
        label: String,
        value:  Array,
        placeholder: String,
        border: {
            type: Boolean,
            default: true
        }
    },
    components: {
        [Field.name]: Field,
        [Popup.name]: Popup,
        [Area.name]: Area,
        [areaList.name]: areaList
    },
    data(){
        return {
            isShowPopup: false,
            areaList: areaList
        }
    },
    computed:{
        valueStr(){
            if(this.value&&this.value.length==3){
                return this.value[0]["name"] + " " + this.value[1]["name"] + " " + this.value[2]["name"]
            }
        },
        valueCode(){
            if(this.value&&this.value.length==3) {
                return this.value[2].code
            }
        }
    },
    methods: {
        confirm(data) {
            this.$emit('input', data);
            this.isShowPopup = false
        },
        cancel(){
            this.isShowPopup = false
        },
    }
}
</script>

<style lang='scss' scoped>
.van-cell.border::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 0;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #ebedf0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5);
}
</style>
